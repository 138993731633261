import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import Style from "styles/privacy.module.scss";

// 突発的なページなのでプライバシーポリシーページのスタイル、レイアウトをそのまま適用

const Privacy = () => (
  <Layout>
    <SEO title="新型コロナウイルス対策に関する弊社対応について" path="news1" />
    <div className={Style.container}>
      <section className={Style.privacyPolicy}>
        <ul className={Style.privacyPolicyList}>
          <li className={Style.privacyPolicyList__item}>
            <h2>新型コロナウイルス対策に関する弊社対応について</h2>
            <p className={Style.privacyPolicyList__text}>
              弊社では、緊急事態宣言を受け、コロナウィルス感染リスクが軽減されるまで本社勤務スタッフを全員テレワーク対応といたします。
              <br />
              弊社にて稼働する業務委託も同様の対応をとっております。
              <br />
              <br />
              実施期間：2021年1月8日（金）～ 2021年12月31日（金）
              <br />
              <br />
              ※在宅終了時期については、感染リスクの状況により延期の場合がございます。
              <br />
              在宅目安は12月31日まででございますが、状況次第では延期いたします。
              <br />
              業務上必要となる打合せ機会については、WEBミーティングツールを用いた オンライン対応に限らせていただきます。
              <br />
              こちらは本社勤務のエンジニア、管理部門スタッフに限った対応となります。
              <br />
              客先常駐しているエンジニアに関しては取引先と安全面の確認を行い、感染リスクに対する対処の合意がされた前提で、
              自宅以外での業務を実施しております。
              <br />
              （現時点では常駐業務の大半も安全面を考慮して、自宅勤務でございます。）
              <br />
              営業、採用等弊社へのすべての問い合わせは、コーポレートサイトの問い合わせフォームから問い合わせいただきますよう
              お願い申し上げます。
            </p>
          </li>
        </ul>
      </section>
    </div>
  </Layout>
);

export default Privacy;
